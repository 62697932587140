import Alert from "bootstrap/js/dist/alert";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
import Offcanvas from "bootstrap/js/dist/offcanvas";
import Tooltip from "bootstrap/js/dist/tooltip";
import TomSelect from "tom-select";
import type { TomOption, TomInput } from "tom-select/dist/esm/types/index.js";

// TODO: move it to a single listener at document level for all [data-method] links
// See [data-confirm] handler below
document.querySelectorAll("a[data-method]").forEach(el => {
  el.addEventListener("click", function (this: HTMLAnchorElement, e: Event) {
    if (this.dataset.confirm) {
      return; // will be handled by confirm handler
    }

    e.preventDefault();

    if (!("csrftoken" in window)) {
      console.error("Define window.csrftoken before using [data-method] links.");
      return;
    }

    const method = this.dataset.method || "get";
    const url = this.href || "";

    const form = document.createElement("form");
    form.method = method;
    form.action = url;

    const csrf = document.createElement("input") as HTMLInputElement;
    csrf.type = "hidden";
    csrf.name = "csrfmiddlewaretoken";
    csrf.value = window.csrftoken as string;

    form.appendChild(csrf);
    document.body.appendChild(form);

    form.submit();
  });
});

document.querySelectorAll("[data-dismiss='dropdown']").forEach(el => {
  el.addEventListener("click", function (this: HTMLElement, e: Event) {
    e.preventDefault();
    const dropdownEl = this.closest(".dropdown")!
      .querySelector("[data-bs-toggle='dropdown']")!;

    const dropdown = Dropdown.getInstance(dropdownEl)!;
    dropdown.hide();
  });
});

document.addEventListener("click", function (e: Event) {
  const target = e.target as HTMLElement;
  if (target.matches("a, button")) {
    return;
  }
  const row = target.closest<HTMLElement>("tr[data-href]");
  if (row) {
    const href = row.dataset.href!;
    window.location.href = href;
  }
});

const collapseElementList = document.querySelectorAll('[data-bs-toggle="collapse"]');
[...collapseElementList].forEach(el => new Collapse(el))

const alertList = document.querySelectorAll('.alert');
[...alertList].forEach(el => new Alert(el))

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
[...tooltipTriggerList].forEach(el => new Tooltip(el))

const offcanvasElementList = document.querySelectorAll('.offcanvas');
[...offcanvasElementList].forEach(el => new Offcanvas(el))

document.querySelectorAll(".js-clipboard").forEach(el => {
  el.addEventListener("click", function (e) {
    e.preventDefault();

    const target = e.currentTarget! as HTMLElement;

    copyToClipboard(target.previousElementSibling! as HTMLInputElement);

    const title = target.dataset.copied || "Copied!";

    const tooltip = Tooltip.getInstance(target);
    if (tooltip) {
      tooltip.setContent({ '.tooltip-inner': title });
    }
  });
});

document.querySelectorAll<TomInput>(".tomselect").forEach(el => {
  new TomSelect(el, {
    maxOptions: 100,
    render: {
      item: function (data: TomOption, escape: (str: string) => string) {
        const label = data.label || data.text;
        return `<div><span class="h5 mb-0">${label}</span></div>`;
      },
      option(data: TomOption, escape: (str: string) => string) {
        if (data.label && data.secondary) {
          return `<div>
                        <span class="h5">${escape(data.label)}</span>
                        <span class="d-block text-truncate">${escape(data.secondary)}</span>
                    </div>`;
        } else {
          const label = data.label || data.text;
          return `<div><span class="h5">${label}</span></div>`;
        }
      },
      no_results(this: TomSelect, _: TomOption, escape: (str: string) => string) {
        const label = this.input.dataset.noResults;
        return label ? `<div class="no-results">${escape(label)}</div>` : "";
      }
    }
  })
});

function copyToClipboard(elem: HTMLInputElement) {
  const target = elem;

  // select the content
  const currentFocus = document.activeElement as HTMLElement;

  target.focus();
  target.setSelectionRange(0, target.value.length);

  // copy the selection
  let succeed;
  try {
    succeed = document.execCommand("copy");
  } catch (e) {
    console.warn(e);
    succeed = false;
  }

  // Restore original focus
  if (currentFocus && typeof currentFocus.focus === "function") {
    currentFocus.focus();
  }
  return succeed;
}

const intentTypes = ["primary", "secondary", "success", "danger", "warning", "info"];

function intentClasses(prefix: string) {
  return intentTypes.map(s => `${prefix}-${s}`);
}

document.addEventListener("click", function (e) {
  const target = e.target as HTMLElement;
  if (target.matches("[data-confirm]")) {
    e.preventDefault();

    const el = target as HTMLAnchorElement;

    const message = el.dataset.confirm || "";

    const modalEl = document.getElementById("confirmModal")!
    modalEl.querySelector(".modal-body .prompt")!.innerHTML = message;

    const form = modalEl?.querySelector("form") as HTMLFormElement
    form.method = el.dataset.method || "get";
    form.action = el.href || "";

    const intent = el.dataset.intent || "danger";
    const icon = el.dataset.icon || "exclamation-lg";

    const submitButton = form.querySelector<HTMLButtonElement>("button[type=submit]")!;

    submitButton.classList.remove(...intentClasses("btn"));
    submitButton.classList.add("btn-" + intent);
    submitButton.innerText = `${el.dataset.label || submitButton.dataset.defaultLabel}`;
    form.onsubmit = function () {
      submitButton.disabled = true;
    }

    const iconContainer = form.querySelector<HTMLDivElement>(".icon-container")!;

    iconContainer.classList.remove(...intentClasses("bg-soft"), ...intentClasses("text"));
    iconContainer.classList.add("bg-soft-" + intent, "text-" + intent);
    iconContainer.children[0].className = "bi bi-" + icon;

    const modal = Modal.getOrCreateInstance(modalEl)
    modal.show();
  }
});

const workspacePicker = document.getElementById("workspacePicker");
workspacePicker?.addEventListener("change", async function (ev) {
  const target = (ev.currentTarget! as HTMLSelectElement)
  const { value } = target;

  const resp = await fetch(
    `/workspaces/${value}/set_default`,
    {
      method: "POST",
      headers: { "X-CSRFToken": window.csrftoken as string },
    }
  )
  if (!resp.ok) {
    console.error("Failed to set default workspace", resp);
  }
})

function isValidIdSelector(id: string): boolean {
  return /^#[a-z][a-z0-9_-]*$/i.test(id);
}

function enableOffCanvasHashOpening() {
  const hash = window.location.hash;
  if (!hash || !isValidIdSelector(hash)) {
    return;
  }

  const target = document.querySelector(hash);
  if (target?.classList.contains("offcanvas")) {
    const offcanvas = Offcanvas.getOrCreateInstance(target)
    offcanvas.show();
  }
}

enableOffCanvasHashOpening();
